var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.contact_person,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"ФИО контактного лица","require":""},on:{"input":function($event){return _vm.upd_field('contact_person', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.position_of_the_contact_person,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Должность контактного лица","require":""},on:{"input":function($event){return _vm.upd_field('position_of_the_contact_person', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"value":_vm.new_org.telephone,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
          ],"label":"Телефон","require":""},on:{"input":function($event){return _vm.upd_field('telephone', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.website,"label":"Сайт"},on:{"input":function($event){return _vm.upd_field('website', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.email_1,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_email(v) || _vm.invalid_email; }
          ],"type":"email","label":"E-mail","hint":"Для обмена электронными документами","persistent-hint":"","require":""},on:{"input":function($event){return _vm.upd_field('email_1', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.email_2,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_email(v) || _vm.invalid_email; }
          ],"type":"email","label":"E-mail","hint":"Для получения лицензий программных продуктов (лицензионный договор)","persistent-hint":"","require":""},on:{"input":function($event){return _vm.upd_field('email_2', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.email_3,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_email(v) || _vm.invalid_email; }
          ],"type":"email","label":"E-mail","hint":"Для получения сублицензий программных продуктов","persistent-hint":"","require":""},on:{"input":function($event){return _vm.upd_field('email_3', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.email_4,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_email(v) || _vm.invalid_email; }
          ],"type":"email","label":"E-mail","hint":"Для получения для получения рассылок","persistent-hint":"","require":""},on:{"input":function($event){return _vm.upd_field('email_4', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.legal_address,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Адрес","hint":"Юридический","persistent-hint":"","require":""},on:{"input":function($event){return _vm.upd_field('legal_address', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.actual_address,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Адрес","hint":"Фактический","persistent-hint":"","require":""},on:{"input":function($event){return _vm.upd_field('actual_address', $event)}}})],1)]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.form_valid},on:{"click":_vm.go_back}},[_vm._v(" Назад ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent darken-4","depressed":"","type":"submit","disabled":!_vm.form_valid}},[_vm._v(" Продолжить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }